<template>
  <div />
</template>

<script>
import auth from '@/@custom/libs/auth'

export default {
  name: 'SilentRenew',
  mounted() {
    auth.signinSilentCallback().then(() => {
    }).catch(() => {
    })
  },
}
</script>
